import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/usr/src/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "kotlin"
    }}>{`Kotlin`}</h1>
    <p><em parentName="p">{`Todo`}</em></p>
    <h2 {...{
      "id": "basics"
    }}>{`Basics`}</h2>
    <h3 {...{
      "id": "statemanagement"
    }}>{`Statemanagement`}</h3>
    <h3 {...{
      "id": "api-management"
    }}>{`Api Management`}</h3>
    <h4 {...{
      "id": "graphql"
    }}>{`GraphQL`}</h4>
    <h4 {...{
      "id": "rest"
    }}>{`REST`}</h4>
    <h3 {...{
      "id": "timemanagement"
    }}>{`Timemanagement`}</h3>
    <h3 {...{
      "id": "testing"
    }}>{`Testing`}</h3>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      